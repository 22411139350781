/*-- -------------------------- -->
<---     Mobile Navigation      -->
<--- -------------------------- -*/
body,
html {
  /* reset margin and padding so there's no gap between the nav and the screen edges */
  margin: 0;
  padding: 0;
}
/* Mobile - 767px */
@media only screen and (max-width: 47.9375rem) {
  #cs-navigation .cs-top-link:nth-of-type(2) {
    display: none;
  }
}
/* Mobile - 1023px */
@media only screen and (max-width: 63.9375rem) {
  body.cs-open {
    overflow: hidden;
  }
  body.cs-open #cs-navigation {
    background-color: #1a1a1a;
  }
  #cs-navigation {
    width: 100%;
    /* prevents padding and border from affecting height and width */
    box-sizing: border-box;
    background-color: rgba(26, 26, 26, 0.84);
    position: fixed;
    z-index: 10000;
    transition: background-color 0.3s;
  }
  #cs-navigation:before {
    content: "";
    width: 100%;
    height: 0vh;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: -1100;
    transition: height 0.5s, opacity 0.5s;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
  #cs-navigation.cs-active:before {
    height: 150vh;
    opacity: 1;
  }
  #cs-navigation.cs-active .cs-ul-wrapper {
    opacity: 1;
    transform: scaleY(1);
    transition-delay: 0.15s;
  }
  #cs-navigation.cs-active .cs-li {
    opacity: 1;
    transform: translateY(0);
  }
  #cs-navigation .cs-top-bar {
    width: 100%;
    background-color: #1a1a1a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #cs-navigation .cs-top-container {
    width: 100%;
    /* prevents padding and border from affecting height and width */
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3.125rem;
  }
  #cs-navigation .cs-top-contact {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
  }
  #cs-navigation .cs-top-link {
    font-size: 0.875rem;
    line-height: 1.5em;
    text-decoration: none;
    margin: 0;
    color: #f7f7f7;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }
  #cs-navigation .cs-link-icon {
    width: 1rem;
    height: auto;
    display: block;
  }
  #cs-navigation .cs-top-social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  #cs-navigation .cs-social-link {
    text-decoration: none;
    width: auto;
    height: auto;
    display: block;
  }
  #cs-navigation .cs-social-icon {
    width: 1.25rem;
    height: auto;
    display: block;
  }
  #cs-navigation .cs-container {
    width: 100%;
    /* prevents padding and border from affecting height and width */
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  #cs-navigation .cs-logo {
    width: 9.1875rem;
    height: auto;
    margin: 0 auto 0 0;
    /* prevents padding and border from affecting height and width */
    box-sizing: border-box;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  #cs-navigation .cs-logo img {
    width: 100%;
    height: 100%;
    /* ensures the image never overflows the container. It stays contained within it's width and height and expands to fill it then stops once it reaches an edge */
    object-fit: contain;
  }
  #cs-navigation .cs-toggle {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0 0 0 auto;
    background-color: #484848;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #cs-navigation .cs-active .cs-line1 {
    top: 50%;
    transform: translate(-50%, -50%) rotate(225deg);
  }
  #cs-navigation .cs-active .cs-line2 {
    top: 50%;
    transform: translate(-50%, -50%) translateY(0) rotate(-225deg);
    transform-origin: center;
  }
  #cs-navigation .cs-active .cs-line3 {
    opacity: 0;
    bottom: 100%;
  }
  #cs-navigation .cs-box {
    /* 24px - 28px */
    width: clamp(1.5rem, 2vw, 1.75rem);
    height: 1rem;
    position: relative;
  }
  #cs-navigation .cs-line {
    width: 100%;
    height: 2px;
    background-color: #fafbfc;
    border-radius: 2px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  #cs-navigation .cs-line1 {
    top: 0;
    transition: transform 0.5s, top 0.3s, left 0.3s;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-direction: normal;
    animation-fill-mode: forwards;
    transform-origin: center;
  }
  #cs-navigation .cs-line2 {
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: top 0.3s, left 0.3s, transform 0.5s;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  #cs-navigation .cs-line3 {
    bottom: 0;
    transition: bottom 0.3s, opacity 0.3s;
  }
  #cs-navigation .cs-ul-wrapper {
    width: 100%;
    height: auto;
    padding-bottom: 3rem;
    background-color: #1a1a1a;
    overflow: hidden;
    box-shadow: inset rgba(0, 0, 0, 0.2) 0px 8px 24px;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -1;
    transform: scaleY(0);
    transition: transform 0.4s, opacity 0.3s;
    transform-origin: top;
  }
  #cs-navigation .cs-ul {
    width: 100%;
    height: auto;
    max-height: 65vh;
    margin: 0;
    padding: 3rem 0 0 0;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.25rem;
  }
  #cs-navigation .cs-li {
    text-align: center;
    list-style: none;
    width: 100%;
    margin-right: 0;
    opacity: 0;
    /* transition from these values */
    transform: translateY(-4.375rem);
    transition: transform 0.6s, opacity 0.9s;
  }
  #cs-navigation .cs-li:nth-of-type(1) {
    transition-delay: 0.05s;
  }
  #cs-navigation .cs-li:nth-of-type(2) {
    transition-delay: 0.1s;
  }
  #cs-navigation .cs-li:nth-of-type(3) {
    transition-delay: 0.15s;
  }
  #cs-navigation .cs-li:nth-of-type(4) {
    transition-delay: 0.2s;
  }
  #cs-navigation .cs-li:nth-of-type(5) {
    transition-delay: 0.25s;
  }
  #cs-navigation .cs-li:nth-of-type(6) {
    transition-delay: 0.3s;
  }
  #cs-navigation .cs-li:nth-of-type(7) {
    transition-delay: 0.35s;
  }
  #cs-navigation .cs-li:nth-of-type(8) {
    transition-delay: 0.4s;
  }
  #cs-navigation .cs-li:nth-of-type(9) {
    transition-delay: 0.45s;
  }
  #cs-navigation .cs-li:nth-of-type(10) {
    transition-delay: 0.5s;
  }
  #cs-navigation .cs-li:nth-of-type(11) {
    transition-delay: 0.55s;
  }
  #cs-navigation .cs-li:nth-of-type(12) {
    transition-delay: 0.6s;
  }
  #cs-navigation .cs-li:nth-of-type(13) {
    transition-delay: 0.65s;
  }
  #cs-navigation .cs-li-link {
    /* 16px - 24px */
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    line-height: 1.2em;
    text-decoration: none;
    margin: 0;
    color: var(--bodyTextColorWhite);
    display: inline-block;
    position: relative;
  }
  #cs-navigation .cs-li-link.cs-active {
    color: var(--secondary);
  }
  #cs-navigation .cs-li-link:hover {
    color: var(--secondary);
  }
  #cs-navigation .cs-button-solid {
    display: none;
  }
}
/*-- -------------------------- -->
<---     Desktop Navigation     -->
<--- -------------------------- -*/
/* Small Desktop - 1024px */
@media only screen and (min-width: 64rem) {
  #cs-navigation {
    width: 100%;
    /* prevents padding and border from affecting height and width */
    box-sizing: border-box;
    padding: 0;
    background-color: rgba(26, 26, 26, 0.84);
    position: fixed;
    z-index: 10000;
  }
  #cs-navigation .cs-top-bar {
    width: 100%;
    background-color: #1a1a1a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #cs-navigation .cs-top-container {
    width: 100%;
    max-width: 80rem;
    /* prevents padding and border from affecting height and width */
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3.125rem;
  }
  #cs-navigation .cs-top-contact {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
  }
  #cs-navigation .cs-top-link {
    font-size: 0.875rem;
    line-height: 1.5em;
    text-decoration: none;
    margin: 0;
    color: #f7f7f7;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }
  #cs-navigation .cs-top-link:hover {
    text-decoration: underline;
  }
  #cs-navigation .cs-link-icon {
    width: 1rem;
    height: auto;
    display: block;
  }
  #cs-navigation .cs-top-social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  #cs-navigation .cs-social-link {
    text-decoration: none;
    width: auto;
    height: auto;
    display: block;
    transition: transform 0.3s;
  }
  #cs-navigation .cs-social-link:hover {
    transform: translateY(-0.25rem);
  }
  #cs-navigation .cs-social-icon {
    width: 1.25rem;
    height: auto;
    display: block;
  }
  #cs-navigation .cs-container {
    width: 100%;
    max-width: 80rem;
    /* same height as the cs-ul-wrapper */
    height: 5.8125rem;
    margin: auto;
    /* prevents padding from affecting height */
    box-sizing: border-box;
    padding: 0 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    position: relative;
  }
  #cs-navigation .cs-toggle {
    display: none;
  }
  #cs-navigation .cs-logo {
    width: 18.4%;
    max-width: 12.3125rem;
    height: 3.75rem;
    /* margin-right auto pushes everything away from it to the right */
    margin: 0 auto 0 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  #cs-navigation .cs-logo img {
    width: 100%;
    height: 100%;
    /* ensures the image never overflows the container. It stays contained within it's width and height and expands to fill it then stops once it reaches an edge */
    object-fit: contain;
  }
  #cs-navigation .cs-ul-wrapper {
    /* absolutely positioned to be dead center */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #cs-navigation .cs-ul {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* 20px - 36px */
    gap: clamp(1.25rem, 2.6vw, 2.25rem);
  }
  #cs-navigation .cs-li {
    list-style: none;
    padding: 2.25rem 0;
    /* prevent flexbox from squishing it */
    flex: none;
  }
  #cs-navigation .cs-li-link {
    /* 14px - 16px */
    font-size: clamp(0.875rem, 0.6vw, 1rem);
    line-height: 1.5em;
    text-decoration: none;
    margin: 0;
    color: var(--bodyTextColorWhite);
    display: block;
    position: relative;
    transition: color 0.3s;
  }
  #cs-navigation .cs-li-link:before {
    content: "";
    width: 0%;
    height: 1px;
    background: currentColor;
    opacity: 1;
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    transition: width 0.3s;
  }
  #cs-navigation .cs-li-link:hover {
    color: var(--secondary);
  }
  #cs-navigation .cs-li-link:hover:before {
    width: 100%;
  }
  #cs-navigation .cs-li-link.cs-active {
    color: var(--secondary);
  }
  #cs-navigation .cs-li-link.cs-active:before {
    width: 100%;
  }
  #cs-navigation .cs-button-solid {
    font-size: 1rem;
    font-weight: 400;
    /* 46px - 56px */
    line-height: clamp(2.875em, 5.5vw, 3.5em);
    text-align: center;
    text-decoration: none;
    min-width: 9.375rem;
    margin: 0;
    /* prevents padding from adding to the width */
    box-sizing: border-box;
    padding: 0 2rem;
    background-color: var(--primary);
    color: #fff;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: color 0.3s;
  }
  #cs-navigation .cs-button-solid:before {
    content: "";
    width: 0%;
    height: 100%;
    background: #fff;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: width 0.3s;
  }
  #cs-navigation .cs-button-solid:hover {
    color: #1a1a1a;
  }
  #cs-navigation .cs-button-solid:hover:before {
    width: 100%;
  }
  #cs-navigation .cs-nav-button {
    line-height: 2.875rem;
  }
}
                                