/*-- -------------------------- -->
<---       Content Group        -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #cs-content {
      width: 85%;
      max-width: 80rem;
      margin: auto;
      margin-bottom: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* 48px - 64px */
      gap: clamp(3rem, 6.5vw, 4rem);
      
    }
    #cs-content .cs-content {
      /* set text align to center if content needs to be center aligned */
      text-align: left;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    #cs-content .cs-flex-wrapper {
      max-width: 37.5rem;
      /* lets the div's contents act as though it doesn't exist and can be children of the next surrounding parent div, in this case the cs-content. That way we can place the button under the cs-wrapper on mobile, but bring it back under the header on tablet */
      display: contents;
    }
    #cs-content .cs-title {
      margin: 0;
      /* 16px - 32px */
      margin-bottom: clamp(1rem, 3vw, 2rem);
    }
    #cs-content .cs-wrapper {
      max-width: 39.5rem;
    }
    #cs-content .cs-text {
      margin: 0 0 2rem 0;
      font-size: 1rem;
    }
    #cs-content .cs-button-solid {
      font-size: 1rem;
      font-weight: 700;
      /* 46px - 56px */
      line-height: clamp(2.875rem, 5.5vw, 3.5rem);
      text-align: center;
      text-decoration: none;
      min-width: 9.375rem;
      margin: 0;
      margin-top: 2rem;
      /* prevents padding from adding to the width */
      box-sizing: border-box;
      padding: 0 1.5rem;
      color: #fff;
      background-color: var(--primary);
      display: inline-block;
      position: relative;
      z-index: 1;
    }
    #cs-content .cs-button-solid:before {
      content: "";
      width: 0%;
      height: 100%;
      background: #000;
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: width 0.3s;
    }
    #cs-content .cs-button-solid:hover:before {
      width: 100%;
    }
    #cs-content .cs-button-solid {
      margin: 0;
      display: inline-block;
      order: 2;
    }
    #cs-content .cs-text {
      max-width: 40rem;
      margin-bottom: 1.5rem;
      position: relative;
      z-index: 1;
    }
    #cs-content .cs-ul {
      max-width: 39.375rem;
      margin: 0 0 2rem 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
    #cs-content .cs-li {
      font-size: var(--bodyFontSize);
      list-style: none;
      line-height: 1.5em;
      width: 100%;
      color: var(--bodyTextColor);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
    }
    #cs-content .cs-check-icon {
      width: 1.5rem;
      height: auto;
      /* adds extra space between the icon and top of parent so it's more centered */
      margin-top: 1px;
      display: block;
    }
  }
  /* Tablet - 768px */
  @media only screen and (min-width: 48rem) {
    #cs-content .cs-content {
      text-align: left;
      max-width: 80rem;
      height: auto;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 3rem;
      columns: 2;
    }
    #cs-content .cs-flex-wrapper {
      width: 40vw;
      display: block;
      /* prevents flexbox from squishing it */
      flex: none;
    }
    #cs-content .cs-ul {
      margin: 0;
    }
  }
  /* Large Desktop - 1300px */
  @media only screen and (min-width: 81.25rem) {
    #cs-content .cs-ul {
      flex-direction: row;
      flex-wrap: wrap;
    }
    #cs-content .cs-li {
      width: 48%;
    }
  }
                                  