/*-- -------------------------- -->
<---          Reviews           -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #reviews-545 {
      padding: var(--sectionPadding);
  }
  #reviews-545 .cs-container {
      width: 100%;
      max-width: 67.5rem;
      margin: auto;
  }
  #reviews-545 .cs-review-group {
      /* padding left and right change to a clamp at tablet */
      padding: 4rem 1.25rem;
      background-color: var(--primary);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2.5rem;
      position: relative;
      z-index: 1;
  }
  #reviews-545 .cs-picture {
      display: block;
      position: relative;
      /* 144px - 256px */
      width: clamp(9rem, 21vw, 16rem);
      /* prevents flexbox from squishing it */
      flex: none;
      /* makes it square */
      aspect-ratio: 1;
  }
  #reviews-545 .cs-picture img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
  }
  #reviews-545 .cs-content {
      max-width: 34.375rem;
  }
  #reviews-545 .cs-review {
      /* 16px - 20px */
      font-size: clamp(1rem, 2vw, 1.25rem);
      line-height: 1.5em;
      text-align: left;
      font-weight: 400;
      /* 20px - 36px */
      margin: 0 0 clamp(1.25rem, 4vw, 2.25rem) 0;
      color: var(--bodyTextColorWhite);
  }
  #reviews-545 .cs-name {
      /* 20px - 25px */
      font-size: clamp(1.25rem, 3vw, 1.5625rem);
      line-height: 1.2em;
      text-align: left;
      font-weight: 700;
      margin: 0 0 0.5rem 0;
      color: var(--bodyTextColorWhite);
      display: block;
  }
  #reviews-545 .cs-desc {
      /* 16px - 20px */
      font-size: clamp(1rem, 3vw, 1.25rem);
      line-height: 1.2em;
      text-align: left;
      font-weight: 400;
      margin: 0;
      color: var(--bodyTextColorWhite);
      display: block;
  }
  #reviews-545 .cs-pattern {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      /* makes it act like a background image */
      object-fit: cover;
      z-index: -1;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  #reviews-545 .cs-review-group {
      /* 60px - 92px left & right */
      padding: 4rem clamp(3.75rem, 8vw, 5.75rem);
      flex-direction: row;
      justify-content: flex-start;
  }
  #reviews-545 .cs-picture {
      /* sends it to the right in the 2nd position */
      order: 2;
  }
}

                              