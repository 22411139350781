/*-- -------------------------- -->
<---        Content Page        -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #content-page-713 {
        padding: var(--sectionPadding);
        background-color: #fff;
        /* clips the wave background from causing overflow issues when it goes off screen */
        overflow: hidden;
        position: relative;
        z-index: 1;
    }
    #content-page-713 .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* 48px - 64px */
        gap: clamp(3rem, 6vw, 4rem);
        position: relative;
        top: 50px;
        margin-bottom: 70px;
    }
    #content-page-713 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: left;
        width: 100%;
        max-width: 46.125rem;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: flex-start;
    }
    #content-page-713 .cs-content img {
        width: 100%;
        height: auto;
        margin: 1rem 0;
        display: block;
    }
    #content-page-713 .cs-title {
        font-size: var(--headerFontSize);
        font-weight: 900;
        line-height: 1.2em;
        text-align: inherit;
        width: 100%;
        max-width: 100%;
        margin: 0 0 1rem 0;
        color: var(--headerColor);
        position: relative;
    }
    #content-page-713 h2,
    #content-page-713 h3,
    #content-page-713 h4,
    #content-page-713 h5,
    #content-page-713 h6 {
        font-weight: 700;
        text-align: inherit;
        margin: 0 0 1rem 0;
        color: var(--headerColor);
    }
    #content-page-713 h2 {
        font-size: 2rem;
        margin-top: 2rem;
    }
    #content-page-713 h3 {
        font-size: 1.5rem;
        color: var(--primary);
    }
    #content-page-713 h4,
    #content-page-713 h5,
    #content-page-713 h6 {
        font-size: 1.25rem;
    }
    #content-page-713 .cs-button-solid {
        margin-bottom: 2rem;
    }
    #content-page-713 .cs-color {
        color: var(--primary);
    }
    #content-page-713 p {
        font-size: var(--bodyFontSize);
        line-height: 1.5em;
        text-align: inherit;
        width: 100%;
        margin: 0 0 1rem 0;
        color: var(--bodyTextColor);
    }
    #content-page-713 p:last-of-type {
        margin-bottom: 2rem;
    }
    #content-page-713 p a {
        font-size: inherit;
        line-height: inherit;
        text-decoration: underline;
        color: var(--primary);
    }
    #content-page-713 ol,
    #content-page-713 ul {
        padding-left: 1.5rem;
        margin: 0 0 2rem 0;
        color: var(--bodyTextColor);
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    #content-page-713 ul li {
        list-style: none;
        color: inherit;
        position: relative;
    }
    #content-page-713 ul li:before {
        /* custom list bullet */
        content: "";
        width: 3px;
        height: 3px;
        background: currentColor;
        opacity: 1;
        border-radius: 50%;
        position: absolute;
        display: block;
        top: 0.625rem;
        left: -0.75rem;
    }
    #content-page-713 .cs-image-group {
        /* scales the whole section down and ties the font size to the vw and stops at 70% of the vale of 1em, changes at desktop */
        font-size: min(1vw, 1em);
        /* everything inside this box is in ems so we can scale it all down proportionally with a font size */
        width: 33.875em;
        height: 48.3125em;
        display: none;
        /* prevents flexbox from squishing it */
        flex: none;
        position: relative;
        /* flips it horizontally */
        transform: scaleX(-1);
    }
    #content-page-713 .cs-picture {
        width: 33.875em;
        height: 40.4375em;
        border-radius: 17.8125em;
        border: 0.75em solid #ffffff;
        background-color: #f7f7f7;
        /* prevents border from affecting height and width */
        box-sizing: border-box;
        /* clips img tag corners */
        overflow: hidden;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    #content-page-713 .cs-picture img {
        width: 100%;
        height: 100%;
        /* makes it act like a background image */
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        /* flips the image to its original orientation */
        transform: scaleX(-1);
    }
    #content-page-713 .cs-flower {
        width: 22.625em;
        height: auto;
        display: block;
        position: absolute;
        bottom: -2.375em;
        right: -3em;
        z-index: -1;
        transform: rotate(142deg);
    }
    #content-page-713 .cs-sense {
        width: 5em;
        height: auto;
        position: absolute;
        top: -0.25em;
        left: 0.625em;
        transform: rotate(90deg);
    }
    #content-page-713 .cs-background {
        display: none;
    }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    #content-page-713 .cs-container {
        flex-direction: row;
        align-items: flex-start;
    }
    #content-page-713 .cs-image-group {
        display: block;
    }
    #content-page-713 .cs-background {
        width: 20%;
        height: 100%;
        background-color: #f7f7f7;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
    #content-page-713 .cs-background img {
        width: 100%;
        height: 100%;
        opacity: 0.2;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
}
/* Large Desktop 1300px */
@media only screen and (min-width: 81.25rem) {
    #content-page-713 .cs-image-group {
        /* position absolute so we can have it overflow the container as seen in the design. */
        font-size: inherit;
        position: absolute;
        top: 0rem;
        right: -6.25rem;
    }
    #content-page-713 .cs-background {
        width: 50%;
        /* with the left edge always positioned at the center line, we push left of the center line by 335px.  This ensures that this element will stay exactly in this position no matter how wide the screen gets */
        margin-left: 20.9375rem;
        right: auto;
        /* sets the left edge of the element to be at the center line */
        left: 50%;
    }
}

                                