/*-- -------------------------- -->
<---          Banner            -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #banner-1403 {
      padding: var(--sectionPadding);
      /* 190px - 268px */
      padding-top: clamp(11.875rem, 25vw, 16.75rem);
      padding-bottom: 6.25rem;
      /* clips the line from causing overflow issues for going off screen */
      overflow: hidden;
      position: relative;
      z-index: 1;
  }
  #banner-1403 .cs-container {
      text-align: center;
      width: 100%;
      max-width: 80rem;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      /* 8px - 12px */
      gap: clamp(0.5rem, 1vw, 0.75rem);
  }
  #banner-1403 .cs-int-title {
      /* 39px - 61px */
      font-size: clamp(2.4375rem, 6.4vw, 3.8125rem);
      font-weight: 900;
      line-height: 1.2em;
      text-align: inherit;
      margin: 0;
      color: var(--bodyTextColorWhite);
      position: relative;
  }
  #banner-1403 .cs-breadcrumbs {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  #banner-1403 .cs-link {
      font-size: 1rem;
      line-height: 1.2em;
      text-decoration: none;
      color: var(--bodyTextColorWhite);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  #banner-1403 .cs-link:last-of-type {
      /* remove the chevron on the last list item */
  }
  #banner-1403 .cs-link:last-of-type::after {
      display: none;
  }
  #banner-1403 .cs-link:after {
      /* chevron */
      content: "";
      width: 0.4375rem;
      height: 0.75rem;
      margin: 0 1rem;
      background: url("https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/white-chev.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      display: block;
  }
  #banner-1403 .cs-link.cs-active {
      color: var(--primary);
  }
  #banner-1403 .cs-background {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
  }
  #banner-1403 .cs-background:before {
      /* gradient overlay */
      content: "";
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.4;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      z-index: 1;
  }
  #banner-1403 .cs-background:after {
      /* gradient overlay */
      content: "";
      width: 100%;
      height: 40%;
      background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#000000),
          to(rgba(0, 0, 0, 0))
      );
      background: -o-linear-gradient(top, #000000 0%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(
          to bottom,
          #000000 0%,
          rgba(0, 0, 0, 0) 100%
      );
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      z-index: 2;
  }
  #banner-1403 .cs-background img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      /* Makes img tag act as a background image */
      object-fit: cover;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  #banner-1403 .cs-background:before {
      width: 50%;
      height: 100%;
      background: -webkit-gradient(
          linear,
          left top,
          right top,
          from(#000000),
          to(rgba(0, 0, 0, 0))
      );
      background: -o-linear-gradient(left, #000000 0%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(
          to right,
          #000000 0%,
          rgba(0, 0, 0, 0) 100%
      );
      opacity: 1;
  }
}

/*-- -------------------------- -->
<---          Contact           -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #contact-1403 {
      padding: var(--sectionPadding);
      padding-bottom: 0;
      background-color: #f7f7f7;
      position: relative;
      z-index: 10;
  }
  #contact-1403 .cs-container {
      width: 100%;
      /* changes to 1280px at tablet */
      max-width: 36.5rem;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      column-gap: auto;
      /* 48px - 64px */
      gap: clamp(2rem, 3vw, 3rem);
      position: relative;
  }
  #contact-1403 .cs-content {
      /* set text align to left if content needs to be left aligned */
      text-align: left;
      width: 100%;
      display: flex;
      flex-direction: column;
      /* centers content horizontally, set to flex-start to left align */
      align-items: flex-start;
  }

  #contact-1403 .cs-title {
      max-width: 23ch;
  }
  #contact-1403 .cs-text {
      margin-bottom: 1rem;
  }
  #contact-1403 .cs-text:last-of-type {
      margin-bottom: 2rem;
  }
  #contact-1403 .cs-ul {
      text-align: left;
      width: 100%;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5rem;
      position: relative;
  }
  #contact-1403 .cs-li {
      list-style: none;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1.25rem;
  }
  #contact-1403 .cs-li:hover .cs-icon-wrapper {
      transform: scale(1.1);
  }
  #contact-1403 .cs-header {
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.2em;
      margin-bottom: 0.75rem;
      color: var(--headerColor);
      display: block;
  }
  #contact-1403 .cs-link {
      font-size: 1rem;
      line-height: 1.5em;
      text-decoration: none;
      color: #767676;
      display: block;
      position: relative;
  }
  #contact-1403 .cs-link:hover {
      text-decoration: underline;
  }
  #contact-1403 .cs-icon-wrapper {
      width: 3.75rem;
      height: 3.75rem;
      margin: 0;
      border-radius: 50%;
      border: 1px solid #bababa;
      display: flex;
      justify-content: center;
      align-items: center;
      /* prevents flexbox from squishing it */
      flex: none;
      transition: transform 0.3s;
  }
  #contact-1403 .cs-icon {
      width: 1.5rem;
      height: auto;
      display: block;
  }
  #contact-1403 .cs-form {
      width: 100%;
      max-width: 39.375rem;
      /* -30px to -100px */
      margin-bottom: calc(clamp(1.875rem, 7vw, 6.25rem) * -1);
      /* 24px - 48px top and bottom */
      /* 16px - 48px left and right */
      padding: clamp(1.5rem, 5.18vw, 3rem) clamp(1rem, 4vw, 3rem);
      /* prevents flexbox from affecting height and width */
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 1rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.75rem;
  }
  #contact-1403 .cs-h3 {
      /* 20px - 39px */
      font-size: clamp(1.25rem, 3vw, 2.4375rem);
      line-height: 1.2em;
      font-weight: 700;
      margin: 0 0 0.25rem 0;
      color: var(--headerColor);
  }
  #contact-1403 .cs-label {
      /* 14px - 16px */
      font-size: clamp(0.875rem, 1.5vw, 1rem);
      width: 100%;
      color: var(--headerColor);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.25rem;
  }
  #contact-1403 .cs-input {
      font-size: 1rem;
      width: 100%;
      height: 3.5rem;
      padding: 0;
      padding-left: 1.5rem;
      color: var(--headerColor);
      background-color: #f7f7f7;
      border-radius: 0.5rem;
      border: none;
      /* prevents padding from adding to height and width */
      box-sizing: border-box;
  }
  #contact-1403 .cs-input::placeholder {
      color: #7d799c;
      opacity: 0.6;
  }
  #contact-1403 .cs-textarea {
      min-height: 7.5rem;
      padding-top: 1.5rem;
      margin-bottom: 0.75rem;
      font-family: inherit;
  }
  #contact-1403 .cs-button-solid {
      font-size: 1rem;
      line-height: 3.5rem;
      text-decoration: none;
      font-weight: 700;
      overflow: hidden;
      margin: 0;
      color: #fff;
      padding: 0 3rem;
      border-radius: 1.875rem;
      background-color: var(--primary);
      display: inline-block;
      position: relative;
      z-index: 1;
      transition: color 0.3s;
  }
  #contact-1403 .cs-button-solid:before {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 0%;
      background: #1a1a1a;
      opacity: 1;
      top: 0;
      left: 0;
      z-index: -1;
      transition: width 0.3s;
  }
  #contact-1403 .cs-button-solid:hover {
      color: var(--primary);
  }
  #contact-1403 .cs-button-solid:hover:before {
      width: 100%;
  }
  #contact-1403 .cs-submit {
      width: 100%;
      min-width: 12.5rem;
      border: none;
  }
  #contact-1403 .cs-submit:hover {
      color: #fff;
      cursor: pointer;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  #contact-1403 .cs-container {
      max-width: 80rem;
      flex-direction: row;
      justify-content: space-between;
  }
  #contact-1403 .cs-content {
      width: 47%;
      /* prevents flexbox from squishing it */
      flex: none;
  }
  #contact-1403 .cs-submit {
      width: auto;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 64rem) {
  #contact-1403 .cs-form {
      width: 46%;
      max-width: 36.125rem;
  }
  #contact-1403 .cs-submit {
      width: auto;
  }
}
/* Large Desktop - 1500px */
@media only screen and (min-width: 93.75rem) {
  #contact-1403 .cs-graphic {
      display: block;
  }
}

/*-- -------------------------- -->
<---    Google Maps Iframe      -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #map-1403 iframe {
      width: 100%;
      /* 400px - 560px */
      height: clamp(25rem, 42vw, 35rem);
  }
}

/*-- -------------------------- -->
<---          Reviews           -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #reviews-1403 {
      /* centers the button */
      text-align: center;
      padding: var(--sectionPadding);
      /* clips the orbs from causing overflow issues */
      overflow: hidden;
      position: relative;
      z-index: 1;
  }
  #reviews-1403 .cs-container {
      width: 100%;
      /* changes to 1024px at tablet */
      max-width: 36.5rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* 48px - 64px */
      gap: clamp(3rem, 6vw, 4rem);
  }
  #reviews-1403 .cs-content {
      /* set text align to left if content needs to be left aligned */
      text-align: center;
      width: 100%;
      display: flex;
      flex-direction: column;
      /* centers content horizontally, set to flex-start to left align */
      align-items: center;
  }
  #reviews-1403 .cs-topper {
      font-size: var(--topperFontSize);
      line-height: 1.2em;
      text-transform: uppercase;
      text-align: inherit;
      letter-spacing: 0.1em;
      font-weight: 700;
      color: var(--primary);
      margin-bottom: 0.25rem;
      display: block;
  }
  #reviews-1403 .cs-title {
      font-size: var(--headerFontSize);
      font-weight: 900;
      line-height: 1.2em;
      text-align: inherit;
      max-width: 43.75rem;
      margin: 0 0 1rem 0;
      color: var(--headerColor);
      position: relative;
  }
  #reviews-1403 .cs-title {
      max-width: 20ch;
      margin: 0;
  }
  #reviews-1403 .cs-card-group {
      width: 100%;
      padding: 0;
      margin: 0;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      /* 16px - 20px */
      gap: clamp(1rem, 2.5vw, 1.15rem);
  }
  #reviews-1403 .cs-item {
      list-style: none;
      text-align: left;
      /* 20px - 60px */
      padding: clamp(1rem, 4vw, 3.75rem);
      background-color: #fffffff5;
      /* 12px - 24px */
      border-radius: clamp(0.75rem, 1.5vw, 1.25rem);
      /* prevents padding from adding to height and width */
      box-sizing: border-box;
      /* clips the pseudo box from overflowing */
      overflow: hidden;
      display: flex;
      grid-column: span 12;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      position: relative;
      z-index: 1;
  }
  #reviews-1403 .cs-item:before {
      /* background color */
      content: "";
      width: 100%;
      height: 100%;
      background: var(--primary);
      opacity: 0.05;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      z-index: -1;
      /* prevents the mouse from interacting it */
      pointer-events: none;
  }
  #reviews-1403 .cs-quote {
      /* 72px - 120px */
      width: clamp(4.5rem, 10vw, 7.5rem);
      height: auto;
      /* 32px - 64px */
      margin-bottom: clamp(2rem, 6vw, 4rem);
      display: block;
  }
  #reviews-1403 .cs-item-text {
      /* 16px - 25px */
      font-size: clamp(1rem, 2.6vw, 1.5625rem);
      line-height: 1.2em;
      font-weight: bold;
      margin: 0;
      /* 24px - 64px */
      margin-bottom: clamp(1.5rem, 6vw, 4rem);
      color: var(--bodyTextColor);
  }
  #reviews-1403 .cs-flex-group {
      /* in case one card has more text than the other, this pushes up against the review text so the name and title are always at the bottom. Only works if parent is a flexbox */
      margin-top: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      /* 8px - 24px */
      gap: clamp(0.8rem, 1.5vw, 1rem);
  }
  #reviews-1403 .cs-profile {
      /* 40px - 52px */
      width: clamp(2.5rem, 5vw, 3.25rem);
      height: clamp(2.5rem, 5vw, 3.25rem);
      margin: 0;
      border-radius: 50%;
      box-sizing: border-box;
      border: 2px solid #fff;
      /* clips image corners to make circle */
      overflow: hidden;
      position: relative;
      display: block;
  }
  #reviews-1403 .cs-profile img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      /* makes the image behave like a background image */
      object-fit: cover;
  }
  #reviews-1403 .cs-name {
      /* 16px - 25px */
      font-size: clamp(1rem, 2.5vw, 1.5625rem);
      line-height: 1.2em;
      font-weight: 700;
      margin: 0;
      color: var(--headerColor);
      display: block;
  }
  #reviews-1403 .cs-job {
      /* 14px - 16px */
      font-size: clamp(0.875rem, 1.5vw, 1rem);
      line-height: 1.5em;
      font-weight: 400;
      margin: 0;
      color: #746f95;
      display: block;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  #reviews-1403 .cs-container {
      max-width: 80rem;
  }
  #reviews-1403 .cs-item {
      grid-column: span 6;
  }
}

                              