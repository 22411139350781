/*-- -------------------------- -->
<---          Services          -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #services-1356 {
        padding: var(--sectionPadding);
        position: relative;
        z-index: 10;
    }
    #services-1356 .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: clamp(3rem, 6vw, 4rem);
    }
    #services-1356 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: center;
    }

    #services-1356 .cs-topper {
        color: var(--primary);
    }
    #services-1356 .cs-title {
        max-width: 25ch;
        margin: 0;
    }
    #services-1356 .cs-card-group {
        margin: 0;
        padding: 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        /* 16px - 20px */
        gap: clamp(1rem, 2vw, 1.25rem);
    }
    #services-1356 .cs-item {
        text-align: center;
        list-style: none;
        width: 100%;
        height: 19.0625rem;
        margin: 0;
        padding: 0;
        background-color: #000;
        border-radius: 1.5rem;
        /* clips background image corners */
        overflow: hidden;
        box-shadow: 0px 12px 80px 0px rgba(26, 26, 26, 0.08);
        /* prevents padding and border from affecting height and width */
        box-sizing: border-box;
        grid-column: span 12;
        grid-row: span 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        position: relative;
        z-index: 1;
    }
    #services-1356 .cs-item:hover .cs-background:before {
        background-color: var(--primary);
        opacity: 0.84;
    }
    #services-1356 .cs-item:hover .cs-background img {
        transform: scale(1.2);
    }
    #services-1356 .cs-link {
        text-decoration: none;
        width: 100%;
        height: 100%;
        /* padding goes on the link, not the cs-item as is normal. We do this because we want the whole card to be hoverable. So we add the padding to the link tag to create the space inside the card. By adding the space inside the cs-link tag we can make the whole card hoverable since the padding is now contributing to the height and widht of the link */
        padding: 1.5rem;
        /* prevents padding from affecting height and width */
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #services-1356 .cs-h3 {
        /* 20px - 25px */
        font-size: clamp(1.25rem, 2.5vw, 1.5625rem);
        line-height: 1.2em;
        font-weight: bold;
        text-align: inherit;
        margin: 0;
        color: var(--bodyTextColorWhite);
        transition: color 0.3s;
    }
    #services-1356 .cs-span {
        /* forces the h3 to alwasy be two lines */
        display: block;
    }
    #services-1356 .cs-background {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    #services-1356 .cs-background:before {
        /* background color overlay */
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: 0.4;
        top: 0;
        left: 0;
        z-index: 1;
        transition:
            background-color 0.3s,
            opacity 0.3s;
    }
    #services-1356 .cs-background img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        /* Makes img tag act as a background image */
        object-fit: cover;
        transition: transform 0.6s;
    }
}
/* Tablet - 600px */
@media only screen and (min-width: 37.5rem) {
    #services-1356 .cs-content {
        text-align: left;
        align-items: flex-start;
    }
    #services-1356 .cs-item {
        grid-column: span 6;
    }
}
/* Desktop - 1024px */
@media only screen and (min-width: 64rem) {
    #services-1356 .cs-item {
        grid-column: span 4;
    }
}

                                