/*-- -------------------------- -->
<---         Why Choose         -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #why-choose-441 {
        padding: var(--sectionPadding);
    }
    #why-choose-441 .cs-container {
        width: 100%;
        /* changes to 1280px at tablet */
        max-width: 34.375em;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* 48px - 64px */
        gap: clamp(3rem, 6vw, 4rem);
    }
    #why-choose-441 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: center;
    }
    #why-choose-441 .cs-topper {
        font-size: var(--topperFontSize);
        line-height: 1.2em;
        text-transform: uppercase;
        text-align: inherit;
        letter-spacing: 0.1em;
        font-weight: 700;
        color: var(--primary);
        margin-bottom: -40px;
        display: block;
    }
    #why-choose-441 .cs-title {
        font-size: var(--headerFontSize);
        font-weight: 900;
        line-height: 1.2em;
        text-align: inherit;
        max-width: 43.75rem;
        margin-bottom: -20px;
        color: var(--headerColor);
        position: relative;
    }
    #why-choose-441 .cs-text {
        font-size: 1.3rem;
        line-height: 1.5em;
        text-align: inherit;
        width: 100%;
        max-width: 40.625rem;
        margin: 0;
        color: var(--bodyTextColor);
    }
    #why-choose-441 .cs-card-group {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        /* 12px - 20px */
        gap: clamp(0.75rem, 1.5vw, 1.25rem);
    }
    #why-choose-441 .cs-item {
        list-style: none;
        width: 100%;
        /* changes on tablet */
        max-width: 28.875rem;
        /* 24px - 40px top & bottom */
        /* 24px - 40px left & right */
        padding: clamp(1.5rem, 3vw, 2.5rem) clamp(1.25rem, 3vw, 2.5rem);
        /* prevents padding and border from affecting height and width */
        box-sizing: border-box;
        border-radius: 1rem;
        background-color: #fff;
        border: 1px solid #dad9e3;
        box-shadow: 0px 24px 54px rgba(87, 107, 147, 0.12);
        position: relative;
        transition:
            background-color 0.3s,
            transform 0.3s,
            box-shadow 0.3s,
            border 0.3s;
    }
    #why-choose-441 .cs-item:before {
        /* green border on hover */
        content: "";
        width: 100%;
        height: 100%;
        background: transparent;
        border: 4px solid var(--primary);
        border-radius: 0.75rem;
        /* prevents border from affecting height and width */
        box-sizing: border-box;
        /* prevents the mouse from interacting with it */
        pointer-events: none;
        opacity: 0;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        transition: opacity 0.3s;
    }
    #why-choose-441 .cs-item:hover {
        background-color: #fff;
        transform: translateY(-0.4375rem);
        border-color: var(--primary);
    }
    #why-choose-441 .cs-item:hover:before {
        opacity: 1;
    }
    #why-choose-441 .cs-link {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    #why-choose-441 .cs-picture {
        /* 40px - 72px */
        width: clamp(2.5rem, 6.5vw, 4.5rem);
        /* 40px - 72px */
        height: clamp(2.5rem, 6.5vw, 4.5rem);
        margin: 0;
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: var(--primary);
        position: relative;
        z-index: 1;
    }
    #why-choose-441 .cs-icon {
        /* 24px - 32px */
        height: clamp(1.5rem, 2vw, 2rem);
        width: auto;
    }
    #why-choose-441 .cs-number {
        font-size: var(--headerFontSize);
        font-weight: 400;
        text-align: center;
        line-height: 1.2em;
        margin: 0;
        margin-bottom: 0.25rem;
        color: var(--headerColor);
        transition: color 0.3s;
    }
    #why-choose-441 .cs-desc {
        /* 14px - 20px */
        font-size: clamp(0.875rem, 1.5vw, 1.25rem);
        text-align: center;
        line-height: 1.5em;
        margin: 0;
        color: var(--bodyTextColor);
        transition: color 0.3s;
    }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    #why-choose-441 .cs-container {
        max-width: 80rem;
        margin-top: -100px;
    }
    #why-choose-441 .cs-card-group {
        flex-direction: row;
        justify-content: center;
        flex-wrap: nowrap;
    }
    #why-choose-441 .cs-item {
        width: 100%;
    }
}

                                