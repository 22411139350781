:root {
  /* Add these styles to your global stylesheet, which is used across all site pages. You only need to do this once. All elements in the library derive their variables and base styles from this central sheet, simplifying site-wide edits. For instance, if you want to modify how your h2's appear across the site, you just update it once in the global styles, and the changes apply everywhere. */
  --primary: #ffba43;
  --primaryLight: #ffba43;
  --secondary: #ffba43;
  --secondaryLight: #ffba43;
  --headerColor: #1a1a1a;
  --bodyTextColor: #4e4b66;
  --bodyTextColorWhite: #fafbfc;
  /* 13px - 16px */
  --topperFontSize: clamp(0.8125rem, 1.6vw, 1rem);
  /* 31px - 49px */
  --headerFontSize: clamp(1.9375rem, 3.9vw, 3.0625rem);
  --bodyFontSize: 1rem;
  /* 60px - 100px top and bottom */
  --sectionPadding: clamp(3.75rem, 7.82vw, 6.25rem) 1rem;
  
}

body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin: 0;
  padding: 0;
}

*, *:before, *:after {
  /* prevents padding from affecting height and width */
  box-sizing: border-box;
}
.cs-topper {
  font-size: var(--topperFontSize);
  line-height: 1.2em;
  text-transform: uppercase;
  text-align: inherit;
  letter-spacing: .1em;
  font-weight: 700;
  color: var(--primary);
  margin-bottom: 0.25rem;
  display: block;
}

.cs-title {
  font-size: var(--headerFontSize);
  font-weight: 800;
  line-height: 1.2em;
  text-align: inherit;
  max-width: 43.75rem;
  margin: 0 0 1rem 0;
  color: var(--headerColor);
  position: relative;
}

.cs-text {
  font-size: var(--bodyFontSize);
  line-height: 1.5em;
  text-align: inherit;
  width: 100%;
  max-width: 40.625rem;
  margin: 0;
  color: var(--bodyTextColor);
}
                    
/* Example of language button styling */
.language-buttons {
  position: fixed;
  top: 150px;
  right: 93px;
  z-index: 1000;
  display: flex;
  gap: 10px; /* Add some space between buttons */
  visibility: hidden;
}

.language-buttons button {
  padding: 10px 15px; /* Button padding */
  font-size: 14px; /* Font size */
  font-weight: 500; /* Slightly bold text */
  background-color: var(--primary); /* Button background color */
  color: white; /* Text color */
  border: none; /* Remove border */
  border-radius: 30px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition */
}

.language-buttons button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

.language-buttons button:active {
  background-color: #004085; /* Even darker blue when active */
  transform: translateY(0); /* Reset the lift effect */
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .language-buttons {
    right: 80px;
    top: 679px;
    visibility: hidden;
  }

  .language-buttons button {
    padding: 27px 40px;
    font-size: 14px;
    border-radius: 25px;
  }

  .language-buttons button:hover {
    background-color: #0056b3; /* Keep the hover effect */
    transform: translateY(-1px); /* Slight lift effect */
  }
}

