/*-- -------------------------- -->
<---           Hero             -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #hero-2042 {
    /* Centers button */
    text-align: center;
    /* 200px - 300px - leaving extra space for the navigation */
    padding: clamp(12.5rem, 25.95vw, 18.75em) 1rem;
    /* prevents the topper line from causing an overflow */
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  #hero-2042 .cs-container {
    width: 100%;
    max-width: 80rem;
    margin: auto;
    /* turned off pointer events on the container so the video stops when you click on the background as well */
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* 120px - 465px */
    gap: clamp(7.5rem, 24vw, 29.0625rem);
  }
  #hero-2042 .cs-content {
    text-align: center;
    width: 100%;
    /* changes to 564px on tablet */
    max-width: 20.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #hero-2042 .cs-title {
    /* 39px - 61px */
    font-size: clamp(2.4375rem, 5vw, 3.8125rem);
    color: var(--bodyTextColorWhite);
  }
  #hero-2042 .cs-text {
    margin-bottom: 2rem;
    color: var(--bodyTextColorWhite);
  }
  #hero-2042 .cs-button-group {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  #hero-2042 .cs-play {
    visibility: hidden;
  }
  #hero-2042 .cs-play.cs-hide {
    opacity: 0;
    transform: scale(0);
  }
  #hero-2042 .cs-button-solid {
    font-size: 1rem;
    font-weight: 700;
    /* 46px - 56px */
    line-height: clamp(2.875rem, 5.5vw, 3.5rem);
    text-align: center;
    text-decoration: none;
    margin: 0;
    /* prevents padding from adding to the width */
    box-sizing: border-box;
    padding: 0 2rem;
    background-color: var(--primary);
    pointer-events: auto;
    color: var(--bodyTextColorWhite);
    border-radius: 0.5rem;
    display: inline-block;
    position: relative;
    z-index: 1;
  }
  #hero-2042 .cs-button-solid:before {
    content: "";
    width: 0%;
    height: 100%;
    background: #000;
    opacity: 1;
    border-radius: 0.25rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: width 0.3s;
  }
  #hero-2042 .cs-button-solid:hover:before {
    width: 100%;
  }
  #hero-2042 .cs-video-wrapper {
    width: 100%;
    /* changes to 90% at tablet, then back to 100% at desktop */
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  #hero-2042 .cs-video-wrapper:hover {
    cursor: pointer;
  }
  #hero-2042 .cs-video-wrapper video,
  #hero-2042 .cs-video-wrapper .cs-picture {
    width: 100%;
    height: 100%;
    /* makes image act as a background image */
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  #hero-2042 .cs-video-wrapper video img,
  #hero-2042 .cs-video-wrapper .cs-picture img {
    width: 100%;
    height: 100%;
    /* makes image act as a background image */
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  #hero-2042 .cs-video-wrapper:before {
    /* Overlay */
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 16.54%, rgba(0, 0, 0, 0.64) 59.41%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    /* prevents the cursor from interacting with it */
    pointer-events: none;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  #hero-2042 .cs-container {
    padding-right: 6.25rem;
    flex-direction: row;
    justify-content: flex-start;
  }
  #hero-2042 .cs-content {
    text-align: left;
    max-width: 35.25rem;
    align-items: flex-start;
  }
  #hero-2042 .cs-video-wrapper::before {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 51.02%, rgba(0, 0, 0, 0.64) 75.13%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }
}
                                